@import "./global.scss";

.app {
  height: 100vh;

  .sections {
    width: 100%;
    height: calc(100vh - 50px);
    background-color: $mainColor;
    position: relative;
    top: 50px;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      width: 100vw;
      height: calc(100vh - 50px);
      scroll-snap-align: start;
    }

    @include mobile {
      > * {
        width: 100vw;
        height: calc(100vh - 50px);
        scroll-snap-align: none;
      }
    }
  }
}
