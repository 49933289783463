@import "../../global.scss";

.contact {
  background-color: $mainColor;
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .left {
    flex: 1;
    overflow: hidden;

    @include mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 10px;
    }

    img {
      height: 100%;
    }
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 700px;

    @include mobile {
      flex: 2.5;
    }

    .contactTitleContainer {
      overflow: hidden;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      h2 {
        font-size: 30px;
        color: white;
      }
    }

    .formContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      form {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include mobile {
          width: 95%;
          height: 95%;
        }

        .inputContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 5px;
          padding-bottom: 10px;
          width: 100%;
          overflow: hidden;

          input {
            width: 90%;
            height: 25px;
            font-size: 14px;

            @include mobile {
              width: 95%;
              height: 18px;
              background-color: white;
            }
          }

          textarea {
            width: 90%;
            height: 170px;
            font-size: 14px;
            margin-top: 5px;
            overflow: hidden;

            @include mobile {
              width: 95%;
              height: 200px;
              overflow: hidden;
              background-color: white;
            }
          }

          button {
            width: 50%;
            height: 30px;
            color: white;
            background-color: $secondaryColor;
            border: none;
            border-radius: 10px;
            font-weight: 500;
            cursor: pointer;
            overflow: hidden;

            @include mobile {
              width: 95%;
            }

            &:focus,
            &:hover {
              outline: none;
              background-color: $mainColorHover;
            }
          }
        }

        span {
          color: green;
        }
      }
    }
  }
}
