@import "../../global.scss";

.splash {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $mainColor;
}
