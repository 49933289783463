@import "../../global.scss";

.powerpostProject {
  background-color: $mainColor;
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .left {
    display: flex;
    background-color: $mainColor;
    flex-direction: column;
    flex: 4;
    justify-content: center;
    align-items: center;

    @include mobile {
      flex: 7;
      padding-bottom: 5px;
    }

    .powerpostProjectTitleContainer {
      display: flex;
      align-items: flex-start;
      padding: 10px;
      margin-left: 10px;
      color: white;
      overflow: hidden;

      h2 {
        font-size: 70px;

        @include mobile {
          font-size: 30px;
        }
      }

      .powerpostProjectTitleIcon {
        padding-left: 5px;
        width: 60px;
        height: 60px;

        @include mobile {
          height: 30px;
          width: 30px;
        }
      }
    }

    .powerpostProjectSubtitleContainer {
      color: white;
      font-size: 30px;
      padding: 5px;

      @include mobile {
        font-size: 12px;
        overflow: hidden;
      }
    }

    .powerpostProjectInfoContainer {
      margin-right: 10px;

      @include mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        overflow: hidden;
      }

      .powerpostProjectInfoContainerDescription {
        margin-top: 10px;
        margin-left: 15px;
        width: 800px;
        height: 330px;
        border-radius: 10px;
        background-color: rgb(233, 233, 233);
        box-shadow: 5px 5px 5px black;
        margin-right: 10px;
        overflow: hidden;

        @include mobile {
          width: 90%;
        }

        .titleContainer {
          display: flex;
          color: $mainColor;
          justify-content: center;
          align-content: center;
          padding-top: 5px;
          font-weight: 500;
          border-bottom: 2px solid darkgrey;
          font-size: 30px;

          @include mobile {
            font-size: 20px;
          }
        }

        .descriptionParagraph {
          margin: 5px;
          padding: 5px;
          font-size: 15px;
          text-align: justify;
          line-height: 1.5;
          color: $mainColor;
          font-size: 25px;

          @include mobile {
            font-size: 12px;
            overflow: hidden;
          }
        }
      }

      .powerpostProjectInfoContainerBuiltWith {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        margin-left: 15px;
        width: 800px;
        height: 350px;
        border-radius: 10px;
        background-color: rgb(233, 233, 233);
        box-shadow: 5px 5px 5px black;
        margin-bottom: 10px;
        margin-right: 10px;

        @include mobile {
          width: 90%;
        }

        .titleContainer {
          display: flex;
          justify-content: center;
          width: 100%;
          font-size: 30px;
          font-weight: 500;
          border-bottom: 2px solid darkgrey;
          color: $mainColor;

          @include mobile {
            font-size: 20px;
          }
        }

        .builtWithIconsContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 95%;
          height: 275px;
          margin: 5px;

          @include mobile {
            height: 180px;
          }

          .builtWithIconContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 150px;

            .builtWithIcon {
              width: 120px;
              height: 120px;

              @include mobile {
                width: 32px;
                height: 32px;
              }
            }

            .builtWithIconDescription {
              font-size: 20px;
              font-weight: 500;

              @include mobile {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .right {
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5px;

    .powerpostProjectMediaContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 50vh;
      width: 45vw;
      align-items: center;
      background-color: rgb(233, 233, 233);
      border-radius: 10px;
      box-shadow: 5px 5px 5px black;

      @include mobile {
        padding: 10px 5px;
        padding-right: 10px;
        width: 90%;
        height: 80%;
      }

      .powerpostProjectGif {
        display: flex;
        justify-content: center;
        align-content: center;

        a {
          overflow: hidden;
        }

        img {
          padding: 10px;
          height: 100%;
          width: 98%;
          overflow: hidden;
        }

        @include mobile {
          width: 95%;
          height: 95%;
          overflow: hidden;
        }
      }
    }

    .linksContainer {
      padding: 10px;
      display: flex;
      justify-content: space-evenly;

      @include mobile {
        padding: 5px;
      }

      a {
        overflow: hidden;
      }

      .linkButton {
        color: white;
        padding: 5px 50px;
        font-size: 20px;
        background-color: $secondaryColor;
        border-radius: 5px;
        border: none;
        box-shadow: 5px 5px 5px black;
        margin: 10px;

        @include mobile {
          font-size: 12px;
          overflow: hidden;
        }

        &:hover {
          background-color: $mainColorHover;
        }
      }
    }
  }
}
