$mainColor: #282828;

$secondaryColor: #0e0d0d;

$mainColorHover: #000000;

$width: 768px;

@mixin mobile {
  @media (max-width: #{$width}) {
    @content;
  }
}
